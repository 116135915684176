<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex items-center justify-between">
      <div>
        {{ product && `${product.name} [${product.sku}]` }}
        <div
          v-if="product && product.average_price"
          class="text-xs text-gray-500"
        >
          Средняя цена: {{ product.average_price }} тг
        </div>
      </div>
      <el-button round size="medium" plain type="primary" @click="save"
        >Сохранить</el-button
      >
    </h1>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(price, cityId) in prices" :key="cityId">
        <div>
          <p class="font-semibold mb-3">
            Цена в городе {{ getCityName(cityId) }}
          </p>
          <el-input
            size="small"
            type="number"
            v-model.number="prices[cityId]"
          ></el-input>
          <div class="mt-5" v-if="getOffer(cityId)">
            Обновлен в: {{ dateTimeFormat(getOffer(cityId).last_updated) }}
            <br />
            <el-table
              class="mt-3"
              :data="getOffer(cityId).offers.offers"
              border
              style="width: 100%"
              size="small"
              ><el-table-column
                type="index"
                label="N"
                align="center"
              ></el-table-column>
              <el-table-column prop="name" label="Название магазина">
                <template v-slot="scope">
                  {{ scope.row.merchantName }}
                </template>
              </el-table-column>
              <el-table-column prop="price" label="Цена"> </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getKaspiProduct, saveKaspiProductPrices } from "@/api/product";
import { getCities } from "@/api/branch";
import { dateTimeFormat } from "@/helpers/formatter";

export default {
  name: "item",
  props: {
    kaspiProductId: {
      type: [String, Number],
      default: "new",
    },
  },
  data() {
    return {
      product: null,
      cities: [],
      prices: {},
      offers: [],
    };
  },
  async mounted() {
    this.cities = await getCities();
    for (let i = 0; i < this.cities.length; i++) {
      this.$set(this.prices, [this.cities[i].id], null);
    }
    if (this.kaspiProductId !== "new") {
      await this.getProduct();
    }
  },
  methods: {
    async getProduct() {
      const { product, prices, offers } = await getKaspiProduct(
        this.kaspiProductId
      );
      this.product = product;
      this.offers = offers;
      for (let i = 0; i < prices.length; i++) {
        this.prices[prices[i].city_id] = prices[i].price;
      }
    },
    async save() {
      try {
        this.loading = true;
        const result = await saveKaspiProductPrices(this.kaspiProductId, {
          prices: this.prices,
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    getOffer(city_id) {
      return this.offers.find((offer) => offer.city_id === city_id);
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime * 1000);
    },
    getCityName(city_id) {
      const city = this.cities.find((city) => city.id === city_id);
      return city ? city.name : city_id;
    },
  },
};
</script>

<style scoped></style>
